import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import SliderContent from '../../components/GSTTemp/SliderContent';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function CompanyRegistration() {
  /* Slider */
  // const secSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online Section 8 Company Registration`,
  //     content: `Secure and safe Section 8 Company Registration`,
  //     image: "/imgs/business/sliders/nidhi-slider.png",
  //     alt_tag: "Online Section 8 company Registration Near Banglore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Section 8 Company Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const secAboutData = {
    header: `Online Section 8 Company Registration`,
    sub_title: `One of the best NGO section 8 company registration service providers in Hosur.`,
    content_paragraph: [
      `The way of enrolling an NGO under the 'Companies Act 2013' is Section 8 Company Registration Online in India. 
      Any firm registered under Section 8 can promote 'Charity,' 'Art,' 'Science,' 'Education,' 'Technology,' 
      'Commerce,' 'Sports,' 'Social Welfare,' 'Religion,' and 'Protection of surrounds or Environment'.`,

      // `Trusts and organizations are similar to Section 8 Companies. Section 8 Companies may be formed for
      // charitable reasons.`,
    ],
  };

  /* Scroll Nav Data */
  const secScrollId = [
    {
      id: `#eligibility`,
      heading: `Eligibility`,
    },
    {
      id: `#sec8procedure`,
      heading: `Procedure`,
    },
    {
      id: `#sec8advantage`,
      heading: `Advantages`,
    },
    {
      id: `#sec8documents`,
      heading: `Documents`,
    },
  ];

  /* Img Content Component Data */
  const secIcData = {
    id: 'eligibility',
    mt_div: '',
    mt_img: '',
    header: 'Section 8 Company Registration Application Eligibility',
    image: '/imgs/registration/ngo/section8.png',
    alt_tag: 'sec 8 company registration',
    points: [
      {
        head: ``,
        content: `A Section-8 company registration can be started in India by an individual,
         HUF, or restricted business.`,
        icon: true,
      },
      {
        head: '',
        content: `Minimum of two individuals who will serve as shareholders or directors 
        of the company must meet all requirements for NGO Section 8 Company registration.`,
        icon: true,
      },
      {
        head: '',
        content: `Any employees of the company are not eligible for compensation of any kind.`,
        icon: true,
      },
      {
        head: '',
        content: `Directly or indirectly, no advantage should be distributed among the Association's directors and members.`,
        icon: true,
      },
      {
        head: '',
        content: `The Company should have a crystal-clear vision and three-year framework plan.`,
        icon: true,
      },
      {
        content: `To comply with regulations, records, reports, and the earnings of the association must be recorded annually with the ROC.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const secCfData = {
    id: 'sec8procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Section 8 Company Registration Procedures',
    body: [
      {
        head: `Registration of an section 8 company`,
        points: ``,
        icon: false,
      },
      {
        head: `Step 1:`,
        points: `12A Registration issuing.`,
        icon: true,
      },
      {
        head: `Step 2:`,
        points: `Giving out an 80G certificate.`,
        icon: true,
      },
      {
        head: `Step 3:`,
        points: ` The MOA and AOA readiness.`,
        icon: true,
      },
      {
        head: 'Step 4',
        points: `The SPICe+ Form's Name Application/Reservation field.`,
        icon: true,
      },
      {
        head: 'Step 5:',
        points: `Brief Description of the Total Number of Board Meetings and its Plenum.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const secCiData = {
    id: 'sec8documents',
    background: 'main-content',
    mt_div: '',
    header: 'Required Documents For Section 8 Company Registration',
    paragraph: ``,
    points: [
      {
        head: '',
        content: `The following documents are required for section 8 
        company registration under "Societies Registration Act, 1860."`,
        icon: true,
      },
      {
        content: `Pan and Aadhaar cards for each member.`,
        icon: true,
      },
      {
        content: `Voter Id/Passport/Driving license.`,
        icon: true,
      },
      {
        content: `Electricity or phone bill.`,
        icon: true,
      },
      {
        content: `Current bank statement for the company and all of the members.`,
        icon: true,
      },
      {
        content: `Rental agreement copy.`,
        icon: true,
      },
      {
        content: `Passport-sized photo all of the members.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme.png',
    alt_tag: 'Section 8 company registration documents',
  };

  /* ImgSlider Data */
  const secImages = [
    '/imgs/assited/invoice-billing.png',
    '/imgs/assited/observation.png',
    '/imgs/assited/ndh.png',
  ];
  /*  Slider Content Component Data */
  const secSCData = {
    id: 'sec8advantage',
    background: [],
    mt_div: 'mt-5',
    header: 'Advantages Of Section 8 Company Registration',
    content: [
      {
        head: '',
        points: `Specific legal identities.`,
        icon: true,
      },
      {
        points: `No minimal investment.`,
        icon: true,
      },
      {
        head: '',
        points: `Stamp duty exemptions,Restrictions for donors,Tax exemptions.`,
        icon: true,
      },
      {
        head: '',
        points: `Certified societies receive the legal standing required for the 
         satutory enthronement of society's wealth.`,
        icon: true,
      },
    ],
    images: secImages,
  };

  var FAQ_data = [
    {
      header: `Is it necessary for the name of a Section 8 organization to come before the words "foundation"?`,
      body: [
        {
          content: `Yes. Rule 8(7) of the Companies (Incorporation) Rules, 2014 states
          that the name of a company falling under Area 8 of the Act must
          include the word "like" or a variation of it. Suppose a Section 8
          Company involved in CSR projects was required to compulsory`,
          icon: false,
        },
      ],
    },
    {
      header: `How many people are required to register a Private Limited Company?`,
      body: [
        {
          content: `A minimum of two people is required to register a section 8 company in India.`,
          icon: false,
        },
      ],
    },
    {
      header: `What are the requirements to be a Director?`,
      body: [
        {
          content: `The Director needs to be over 18 years of age and must be a natural person. There are no
           limitations in terms of citizenship or residency. Therefore, even foreign nationals can be Directors 
           in an Indian Section 8 Company.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is an office required for starting a Section 8 Company?`,
      body: [
        {
          content: `An address in India where the registered office of the Company will be situated is required.
           The premises can be commercial/industrial/residential where communication from the MCA will be received.`,
          icon: false,
        },
      ],
    },

    {
      header:
        'Do I have to be present in-person to incorporate a Section 8 Company?',
      body: [
        {
          content: `No, you will not have to be present at our office or appear at any office for the registration of a 
          Section 8 Company. All the documents can be scanned and sent through email to our office. Some documents will
           also have to be couriered to our office.`,
          icon: false,
        },
      ],
    },
    {
      header: `Whether or not a "One Person Company" can be converted to a Section
      8 company?`,
      body: [
        {
          content: ` It is true that a single organization can transform into a
          private/public entity and then regain its previous status is
          appropriate within Section 8.`,
          icon: false,
        },
      ],
    },
    {
      header: ` How long is the registration of the Company valid?`,
      body: [
        {
          content: `Once a Company is incorporated, it will be active and in existence as long as the annual 
          compliances are met with regularly. In case, annual compliances are not complied with, the Company 
          will become a Dormant Company and may be struck off from the register after a period of time. 
          A struck-off Company can be revived for a period of up to 20 years.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is a Digital Signature Certificate?`,
      body: [
        {
          content: `A Digital Signature establishes the identity of the sender or signee electronically while filing
           documents through the Internet. The Ministry of Corporate Affairs (MCA) mandates that the Directors sign 
           some of the application documents using their Digital Signature. Hence, a Digital Signature is required 
           for all Directors of a proposed Section 8 Company.`,
          icon: false,
        },
      ],
    },
    {
      header: `Regardless of whether an Independent Director is required for a
      Section 8 Company'?`,
      body: [
        {
          content: `No, Section 8 companies are exempt from the obligation to appoint
          independent directors.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What are the stamp duty instalment exemptions?',
      body: [
        {
          content: `Depending on the circumstances, no state allows the issuance of
          share certificates.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Section-8 Company Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={secSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={secAboutData} />

          <Counter />

          <ScrollNav scroll_data={secScrollId} />

          <ImgContent item={secIcData} />

          <ContentForm CFSection_data={secCfData} />

          <SliderContent ScSection_data={secSCData} />

          <ContentImg CISection_data={secCiData} />

          <Cta />

          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
